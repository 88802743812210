import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ssh-keys-setup"
    }}>{`SSH Keys Setup`}</h1>
    <p>{`We share one key across our dev team. This key is on most of our staging servers.`}</p>
    <h2 {...{
      "id": "create-the-key-file"
    }}>{`Create the key file`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`touch ~/.ssh/development.pem
`}</code></pre>
    <p>{`Edit the file and paste the key content, you got from the Lead Developer or from a Senior Developer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`nano ~/.ssh/development.pem
`}</code></pre>
    <p>{`Save the file and change the permissions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`chmod 600 ~/.ssh/development.pem
`}</code></pre>
    <h2 {...{
      "id": "edit-the-config-file"
    }}>{`Edit the Config file`}</h2>
    <p>{`Open the .ssh folder and lets see if you have a `}<inlineCode parentName="p">{`config`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd ~/.ssh && ls -la
`}</code></pre>
    <p>{`If there is a `}<inlineCode parentName="p">{`config`}</inlineCode>{` file, open it and paste the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Host *
        ServerAliveInterval 240
        UseKeychain yes

Host staging
        Hostname 104.248.35.120
        User forge
        IdentityFile ~/.ssh/development.pem

IdentityFile ~/.ssh/development.pem
`}</code></pre>
    <h2 {...{
      "id": "test-the-connection"
    }}>{`Test the connection`}</h2>
    <p>{`Save the file and test the connection to our staging server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ssh staging
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ssh forge@104.248.35.120
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      